export const vehicleAlertsUrl = 'vehicle/vehicle_alerts';
export const vehicleTripsUrl = 'vehicle/trips/';
export const vehicleTripSummaryUrl = 'vehicle/trip_summary/';
export const vehicleDestinationsUrl = 'vehicle/destinations/';
export const vehicleChargeLocationsUrl = 'vehicle/charge_locations/';
export const chargingScheduleUrl = 'vehicle/charging_schedule/';
export const vehicleOwnershipUrl = 'vehicle/ownership/';
export const chargingInvoicesUrl = 'vehicle/charging_invoices/';
export const homeChargingInvoicesUrl = 'vehicle/home_charging_invoices/';
export const chargingHistoryUrl = 'vehicle/charge_history/';
// Geofences
export const vehicleGeofenceUrl = 'vehicle/geofence/';
// Control
export const vehicleLogsUrl = 'vehicle/logs/';

export const vehicleCheckAccessUrl = 'vehicle/check_access/';
export const vehicleWakeUpUrl = 'vehicle/wake_up/';
export const vehicleLockUrl = 'vehicle/doors/';
export const vehicleTrunkUrl = 'vehicle/trunk/';
export const vehicleLightsUrl = 'vehicle/flash_lights/';
export const vehicleHornUrl = 'vehicle/honk_horn/';

export const vehicleAirConditioningUrl = 'vehicle/ac/';
export const vehicleTemperatureUrl = 'vehicle/set_temperature/';
export const vehicleClimateKeeperModeUrl = 'vehicle/set_climate_keeper_mode/';
export const vehicleCOPTempUrl = 'vehicle/set_cop_temp/';
export const vehicleCabinOverheatProtectionUrl = 'vehicle/set_cabin_overheat_protection/';

export const vehicleChargingSettingsUrl = 'vehicle/charging_settings/';
export const vehicleChargingControlUrl = 'vehicle/manual_charging/';

export const vehicleSentryModeUrl = 'vehicle/sentry_mode/';
export const vehicleValetModeUrl = 'vehicle/valet_mode/';
export const vehicleSpeedLimitModeUrl = 'vehicle/speed_limit_mode/';
export const vehicleSetSpeedLimitUrl = 'vehicle/set_speed_limit/';
export const vehicleSetAntiTheftModeUrl = 'vehicle/set_anti_theft_mode/';

export const vehicleScheduleSoftwareUpdateUrl = 'vehicle/schedule_software_update/';

export const vehicleDriverInviteUrl = 'vehicle/driver_invite/';

export const liveTrafficUrl = 'data/here-traffic';
export const liveRouteUrl = 'data/route/';

export const rangeEstimateUrl = 'vehicle/real_range_estimate/';

export const phantomDrainUrl = 'analytics/phantom_drain/';

export const weatherUrl = 'vehicle/weather/';

export const vehicleNavigationUrl = 'vehicle/set_navigation/';
